"use client"
import * as Tabs from "@radix-ui/react-tabs"
import { RiCodeSSlashLine, RiExpandDiagonalLine, RiFlagFill, RiGlobalLine, RiShapesLine } from "@remixicon/react"
import Arrow from "../Arrow"
import { useContext, useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../Dropdown";
import { ThemeContext } from "../../Theme";
export default function CodeExampleTabs({
  tab1,
  tab2,
  tab3,
}: {
  tab1?: any;
  tab2?: any;
  tab3?: any;
}) {
  const [tab,setTab] = useState("tab1")
  const [selected, setSelected] = useState({
    name: "See everything",
    description: `Get a complete view of your infrastructure, data, identities, configurations, and security posture across all your clouds and tools.`,
  });
  const { theme, changeTheme } = useContext(ThemeContext);
  const [color, setColors] = useState("");
    useEffect(()=>{
      if (theme == "system") {
        if (window.matchMedia) {
          // Check if the dark-mode Media-Query matches
          if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
           setColors("dark");
          } else {
             setColors("light");
          }
        } else {
              setColors("dark");
  
        }
      } else {
        setColors(theme)
      }
    },[theme])
  return (
    <Tabs.Root
      className="sm:mt-14 mt-5 flex w-full flex-col sm:grid grid-cols-12 sm:gap-16 gap-4 p-3 sm:p-0"
      defaultValue="tab1"
      orientation="vertical"
      value={tab}
      onValueChange={(value) => {
        setTab(value);
      }}
    >
      <div className="sm:hidden flex flex-col justify-center items-center w-full h-full gap-3">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="flex justify-center items-center w-full">
              <span className="text-black  text-xl dark:text-white flex flex-row gap-2 justify-center items-center rounded-3xl  p-1 px-3 border border-slate-700 dark:border-slate-100 text-center">
                {selected.name}{" "}
                <svg
                  className="-mr-1 size-5 text-gray-400 "
                  viewBox="0 0 20 20"
                  fill={color === "dark" ? "white" : "black"}
                  aria-hidden="true"
                  data-slot="icon"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem
              onClick={() => {
                setSelected({
                  name: "See everything",
                  description: `Get a complete view of your infrastructure, data, identities, configurations, and security posture across all your clouds and tools.`,
                });
                setTab("tab1");
              }}
            >
              See everything
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={() => {
                setSelected({
                  name: "Govern anything",
                  description: `Check and enforce compliance for configurations, processes, and security everywhere.`,
                });
                setTab("tab2");
              }}
            >
              {" "}
              Govern anything
            </DropdownMenuItem>
            <DropdownMenuSeparator />

            <DropdownMenuItem
              onClick={() => {
                setSelected({
                  name: "Adapt and Automate",
                  // @ts-ignore
                  description: `Define any rule (best practices, regulatory, changes) as a query, manage it in Git, and integrate it into your pipelines.`,
                });
                setTab("tab3");
              }}
            >
              {" "}
              Adapt easily
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <div className="w-full h-full ">
          <p className="mt-4 w-full leading-6  sm:inline-block text-center  text-gray-600 dark:text-gray-400">
            {selected.description}
          </p>
        </div>
      </div>
      <Tabs.List
        className="col-span-full hidden sm:flex w-full justify-center flex-col gap-6  md:col-span-4"
        aria-label="Select code"
      >
        <Tabs.Trigger
          className="group relative bg-white h-fit dark:bg-gray-800  flex  flex-col items-start justify-start rounded-xl p-4 text-left shadow-lg ring-1 ring-gray-200 dark:ring-white/5 dark:data-[state=active]:shadow-indigo-900/30 data-[state=active]:ring-gray-500 "
          value="tab1"
        >
          <div className="absolute -right-[36px] top-1/2 hidden -rotate-90 group-data-[state=active]:flex">
            <Arrow
              width={18}
              height={8}
              className="fill-gray-950 dark:fill-gray-900"
            />
          </div>
          <div className="flex items-center gap-4">
            <div className="aspect-square w-fit rounded-lg bg-white p-2 text-gray-700 ring-1 ring-black/10 transition-all group-data-[state=active]:text-indigo-600 group-data-[state=active]:shadow-md group-data-[state=active]:shadow-indigo-500/20 dark:bg-gray-950 dark:text-gray-400 dark:ring-white/10 dark:group-data-[state=active]:text-indigo-400 dark:group-data-[state=active]:shadow-indigo-600/50">
              <RiGlobalLine aria-hidden="true" className="size-4" />
            </div>
            <p className="font-semibold tracking-tight text-gray-700 transition-all group-data-[state=active]:text-indigo-600 sm:text-md dark:text-gray-400 dark:group-data-[state=active]:text-indigo-400">
              See Everything
            </p>
          </div>
          <p className="mt-4 leading-6 sm:inline-block hidden text-gray-600 dark:text-gray-400">
            See Everything in Your Stack: Data, Infrastructure, Identities,
            Configurations, and thousands more—across any cloud, any platform,
            anywhere.
          </p>
        </Tabs.Trigger>{" "}
        <Tabs.Trigger
          className="group relative bg-white h-fit dark:bg-gray-800 flex  flex-col items-start justify-start rounded-xl p-4 text-left shadow-lg ring-1 ring-gray-200 dark:ring-white/5 dark:data-[state=active]:shadow-indigo-900/30 data-[state=active]:ring-gray-500"
          value="tab2"
        >
          <div className="absolute -right-[36px] top-1/2 hidden -rotate-90  sm:group-data-[state=active]:flex">
            <Arrow
              width={18}
              height={8}
              className="fill-gray-950 dark:fill-gray-900"
            />
          </div>
          <div className="flex items-center gap-4">
            <div className="data-state aspect-square w-fit rounded-lg bg-white p-2 text-gray-700 ring-1 ring-black/10 transition-all group-data-[state=active]:text-indigo-600 group-data-[state=active]:shadow-md group-data-[state=active]:shadow-indigo-500/20 dark:bg-gray-950 dark:text-gray-400 dark:ring-white/10 dark:group-data-[state=active]:text-indigo-400 dark:group-data-[state=active]:shadow-indigo-600/50">
              <RiFlagFill aria-hidden="true" className="size-4" />
            </div>
            <p className="font-semibold tracking-tight text-gray-700 transition-all group-data-[state=active]:text-indigo-600 sm:text-md dark:text-gray-400 dark:group-data-[state=active]:text-indigo-400">
              Govern Anything
            </p>
          </div>
          <p className="mt-4 leading-6 text-gray-600 sm:inline-block hidden dark:text-gray-400">
            Govern all changes across clouds & platform. Ensure regulatory, best
            practice, and process conformance.
          </p>
        </Tabs.Trigger>
        <Tabs.Trigger
          className="group w-full relative  bg-white h-fit dark:bg-gray-800 flex  flex-col items-start justify-start rounded-xl p-4 text-left shadow-lg ring-1 ring-gray-200 dark:ring-white/5 dark:data-[state=active]:shadow-indigo-900/30 data-[state=active]:ring-gray-500"
          value="tab3"
        >
          <div className="absolute -right-[36px] top-1/2 hidden -rotate-90 sm:group-data-[state=active]:flex">
            <Arrow
              width={18}
              height={8}
              className="fill-gray-950 dark:fill-gray-900"
            />
          </div>
          <div className="flex items-center gap-4">
            <div className="data-state aspect-square w-fit rounded-lg bg-white p-2 text-gray-700 ring-1 ring-black/10 transition-all group-data-[state=active]:text-indigo-600 group-data-[state=active]:shadow-md group-data-[state=active]:shadow-indigo-500/20 dark:bg-gray-950 dark:text-gray-400 dark:ring-white/10 dark:group-data-[state=active]:text-indigo-400 dark:group-data-[state=active]:shadow-indigo-600/50">
              <RiExpandDiagonalLine aria-hidden="true" className="size-4" />
            </div>
            <p className="font-semibold tracking-tight text-gray-700 transition-all group-data-[state=active]:text-indigo-600 sm:text-md dark:text-gray-400 dark:group-data-[state=active]:text-indigo-400">
              Adapt & Automate
            </p>
          </div>
          <p className="mt-4 leading-7 sm:inline-block hidden text-gray-600 dark:text-gray-400">
            Define checks as SQL queries, managed in Git, integrated into
            pipelines. Use 50+ integrations or write your own.
          </p>
        </Tabs.Trigger>
      </Tabs.List>
      <div className="col-span-full mt-4 sm:mt-0 h-full md:col-span-8 ">
        <Tabs.Content className="h-full w-full" value="tab1">
          <div className="p-4 bg-[#A1ADB8] dark:bg-gray-700 rounded-xl">
            {tab1}
          </div>
        </Tabs.Content>
        <Tabs.Content className="h-full w-full " value="tab2">
          <div className="p-4 bg-[#A1ADB8] dark:bg-gray-700 rounded-xl">
            {tab2}
          </div>
        </Tabs.Content>
        <Tabs.Content className="h-full w-full " value="tab3">
          {tab3}
        </Tabs.Content>
      </div>
    </Tabs.Root>
  );
}
