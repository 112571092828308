import {
  Button,
  Container,
  FormField,
  Header,
  Icon,
  Input,
  KeyValuePairs,
  Link,
  Modal,
  Pagination,
  Popover,
  SpaceBetween,
  Tabs,
  Wizard,
} from "@cloudscape-design/components";

import { useEffect, useState } from "react";
// @ts-ignore

import Definition from "../../images/famework-definition.svg";
import Organization from "../../images/framework-organization.svg";

import {
  RiAppsLine,
  RiArrowDownSLine,
  RiArrowDownWideLine,
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiArrowUpSLine,
  RiArrowUpWideLine,
  RiArticleLine,
  RiBook3Line,
  RiBracesLine,
  RiBrainLine,
  RiBugLine,
  RiFileCodeLine,
  RiFileZipLine,
  RiFolder2Line,
  RiGitMergeLine,
  RiGroup2Line,
  RiIdCardLine,
  RiInfinityLine,
  RiKeyLine,
  RiPlugLine,
  RiPuzzleLine,
  RiRectangleFill,
  RiRectangleLine,
  RiSearchFill,
  RiSearchLine,
  RiServerLine,
  RiShieldUserLine,
  RiSkipDownLine,
  RiSkipUpLine,
  RiStickyNoteLine,
} from "@remixicon/react";
import { Viewer } from "../Viewer";
import { Col, Grid } from "@tremor/react";
import { useNavigate } from "react-router-dom";

import { Results } from "../../pages/landing/query_result";

import axios from "axios";
import { Editor } from "../Editor";
import HeroImage from "./HeroImage";
import ThemedImage from "./ThemedImage";
import gsap from "gsap";
import { useRef } from "react";
import { useGSAP } from "@gsap/react";
import useGSAPAnimation from "./scroller";

const CARDS = [
  {
    title: "Connect your platforms.",
    time: 15,
    content: (
      <div className="rounded-xl w-full   h-full ">
        <div className="iframe-div1     bg-transparent      animate-slide-up-fade  ">
          <iframe
            src="https://tour.opencomply.io/embed/cm5wyzm5901fj010iufslb7bm?embed_v=2"
            loading="lazy"
            title="Website - Product Tour"
            allow="clipboard-write"
            frameBorder="0"
            allowFullScreen={true}
            className="iframe-div-frame rounded-xl"
          ></iframe>
        </div>
      </div>
    ),
  },
  {
    title: "Gain universal visibility.",
    time: 20,

    content: (
      <div className="rounded-xl w-full   h-full ">
        <div className="iframe-div1     bg-transparent      animate-slide-up-fade  ">
          <iframe
            src="https://tour.opencomply.io/embed/cm6i4rxo502rwpe33rzkoofwu?embed_v=2"
            loading="lazy"
            title="Website - Product Tour"
            allow="clipboard-write"
            frameBorder="0"
            allowFullScreen={true}
            className="iframe-div-frame rounded-xl"
          ></iframe>
        </div>
      </div>
    ),
  },
  {
    title: "Identify misconfigurations.",
    time: 20,

    content: (
      <div className="rounded-xl w-full  ">
        <div className="iframe-div1     bg-transparent      animate-slide-up-fade sm:ml-auto w-full ">
          <iframe
            src="https://tour.opencomply.io/embed/cm6pmtbq200yupech7ujwa5xr?embed_v=2"
            loading="lazy"
            title="Website - Product Tour"
            allow="clipboard-write"
            frameBorder="0"
            allowFullScreen={true}
            className="iframe-div-frame rounded-xl"
          ></iframe>
        </div>
      </div>
    ),
  },
  {
    title: "Establish guardrails.",
    time: 20,

    content: (
      <div className="rounded-xl  w-full flex flex-col gap-4 ">
        <div className="iframe-div1   bg-transparent      animate-slide-up-fade w-full ">
          <iframe
            src="https://tour.opencomply.io/embed/cm6pd8ryl0ixgagqwhrhmtb2y?embed_v=2"
            loading="lazy"
            title="Website - Product Tour"
            allow="clipboard-write"
            frameBorder="0"
            allowFullScreen={true}
            className="iframe-div-frame rounded-xl"
          ></iframe>
        </div>
      </div>
    ),
  },

  {
    title: "Automate compliance.",
    time: 20,

    content: (
      <div className="rounded-xl w-full  flex flex-col gap-4 ">
        <div className="iframe-div1   bg-transparent      animate-slide-up-fade w-full ">
          {" "}
          <iframe
            src="https://tour.opencomply.io/embed/cm6i5ble0002vpe9f6bzfav67?embed_v=2"
            loading="lazy"
            title="Website - Product Tour"
            allow="clipboard-write"
            frameBorder="0"
            allowFullScreen={true}
            className="iframe-div-frame rounded-xl"
          ></iframe>
        </div>
      </div>
    ),
  },
];

export default function UseCaseNew2() {
  const [selected, setSelected] = useState(0);
  const container = useRef(null);
  const [time, setTime] = useState(0);
  const timeRef = useRef(time); // Use a ref to hold the current time value
  useEffect(() => {
    timeRef.current = time; // Update the ref whenever `time` changes
  }, [time]);
  useEffect(() => {
    const countdownInterval = setInterval(() => {
      // @ts-ignore
      const video_dur = CARDS[selected]?.time;

      if (timeRef.current >= video_dur) {
        setSelected((selected + 1) % CARDS.length);
        clearInterval(countdownInterval);
        setTime(0);
      } else {
        setTime((prevTime) => prevTime + 1); // Update time in state
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [selected]);

  return (
    <>
      <section
        aria-labelledby="code-example-title"
        className={`mx-auto mt-2 sm:mt-8 h-max min-h-[40vh]    w-full max-w-6xl  2xl:max-w-7xl sm:flex hidden sm:flex-row flex-col sm:p-0 p-6 gap-4 justify-between`}
        // style={{ height: `${CARDS.length * 40}vh` }}
      >
        <div className=" w-full pin-section ">
          <div
            ref={container}
            className="section    cards-section      w-full    "
          >
            <div className="w-full max-w-6xl 2xl:max-w-6xl px-3 flex flex-col justify-center items-center  mb-20">
              <h3
                id="code-example-title"
                className="mt-2 inline-block text-center bg-gradient-to-br from-gray-900 to-gray-800 bg-clip-text py-2 text-3xl  tracking-tighter text-transparent sm:text-5xl md:text-4xl dark:from-gray-50 dark:to-gray-300"
              >
                Full-Stack Security & Compliance
              </h3>

              <p className="sm:mt-6 sm:inline hidden text-center mt-4 max-w-2xl text-lg tracking-tighter text-gray-800 dark:text-gray-400 px-2 font-semibold md:text-1xl">
                Simple. Easy to use. Zero Compromises.
              </p>
            </div>
            <div className="cards w-full    ">
              {CARDS?.map((card: any, index: number) => {
                return (
                  <>
                    <div
                      className={`card h-full transition-opacity duration-500 ease-in-out ${
                        index === selected
                          ? "opacity-100 visible"
                          : "opacity-0 invisible"
                      }`}
                    >
                      <div
                        className={`flex w-full sm:flex-row flex-col justify-center items-center sm:gap-16 gap-4 ${index == selected ? "flex" : "hidden"}`}
                      >
                        <div className="  font-semibold sm:min-w-max text-slate-900 flex justify-center items-center   h-full text-center  dark:text-white sm:text-3xl text-2xl">
                          {card.title}
                        </div>
                        <div className="w-full ">{card.content}</div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div
              className={` flex flex-row w-full justify-center items-center gap-12    mt-8 `}
            >
              <div
                onClick={() => {
                  if (selected > 0) {
                    setSelected(selected - 1);
                  }
                }}
                className={`cursor-pointer font-semibold sm:min-w-max text-indigo-600 dark:text-indigo-400  flex justify-end items-end    h-full      `}
              >
                <RiArrowLeftSLine size={30} />
              </div>
              <div className="flex flex-row gap-4  justify-center items-center ">
                {CARDS?.map((card: any, index: number) => {
                  return (
                    <>
                      <div
                        onClick={() => {
                          setSelected(index);
                        }}
                        className={`   h-3 cursor-pointer rounded-3xl transition-all   duration-500 ease-in-out ${
                          index === selected
                            ? "w-8 bg-indigo-200 dark:bg-indigo-100"
                            : "w-3 bg-indigo-600 dark:bg-indigo-400"
                        }`}
                      >
                        {index == selected && (
                          <>
                            <div
                              className="bg-indigo-600 dark:bg-indigo-400 h-full rounded-3xl transition-all"
                              style={{ width: `${(timeRef.current / card?.time)*100}%` }}
                            ></div>
                          </>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
              <div
                onClick={() => {
                  if (selected < CARDS.length - 1) {
                    setSelected(selected + 1);
                  }
                }}
                className={`cursor-pointer font-semibold sm:min-w-max text-indigo-600 dark:text-indigo-400  flex justify-end items-end    h-full   `}
              >
                <RiArrowRightSLine size={30} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        aria-labelledby="code-example-title"
        className={`mx-auto mt-2 sm:mt-8 2xl:h-[${CARDS.length * 40}vh] xl:h-[${CARDS.length * 40 - 10}vh]  sm:hidden     w-full max-w-6xl  2xl:max-w-7xl flex  sm:flex-row flex-col sm:p-0 p-6 gap-4 justify-between`}
      >
        <div className="w-full max-w-6xl 2xl:max-w-6xl px-3 flex flex-col justify-center items-center  mb-8">
          <h3
            id="code-example-title"
            className="mt-2 inline-block text-center bg-gradient-to-br from-gray-900 to-gray-800 bg-clip-text py-2 text-3xl font-semibold tracking-tighter text-transparent sm:text-5xl md:text-5xl dark:from-gray-50 dark:to-gray-300"
          >
            Full Stack Security & Compliance
          </h3>
          <p className="sm:mt-6 sm:inline hidden text-center mt-4 max-w-2xl text-lg tracking-tighter text-gray-800 dark:text-gray-400 px-2">
            <b>opencomply</b> makes security and compliance so simple and
            intuitive, you'll wonder how you managed before.
          </p>
        </div>
        <div className=" w-full flex flex-col gap-8    ">
          {CARDS?.map((card: any) => {
            return (
              <>
                <div className=" h-full ">
                  <div className="flex w-full sm:flex-row flex-col sm:gap-16 gap-6">
                    <div className="  font-semibold sm:min-w-max text-slate-900 flex justify-center items-center sm:pt-52  h-full text-center  dark:text-white sm:text-3xl text-2xl">
                      {card.title}
                    </div>
                    {card.content}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </section>
    </>
  );
}
