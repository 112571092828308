import React, { Fragment, useContext, useEffect, useState } from "react";
import { getAPIUrl } from "../../lib/utils";
import axios from "axios";
import { Col, Grid } from "@tremor/react";
import { RiCheckLine, RiCodeSSlashLine } from "@remixicon/react";
import Badge from "../../components/Badge";
import { ThemeContext } from "../../Theme";
export interface RoadmapItem {
  title: string;
  stage: string;
  progress: number;
  description: string;
}

export default function Roadmap() {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState<RoadmapItem[]>([]);
  const [loading, setLoading] = useState(false);
   const { theme, changeTheme } = useContext(ThemeContext);
   const [color, setColor] = useState("black");

   useEffect(() => {
     switch (theme) {
       case "light":
         setColor("black");
         break;
       case "dark":
         setColor("white");
         break;
       case "system":
         if (window.matchMedia) {
           // Check if the dark-mode Media-Query matches
           if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
             setColor("white");
           } else {
             setColor("black");
           }
         } else {
           setColor("white");
         }
         break;

       default:
         setColor("black");
         break;
     }
   }, [theme]);
  const getRoadmap = () => {
    setLoading(true);
    axios
      .get(`https://content.opencomply.io/website/roadmap.json`)
      .then((res) => {
        if (res.data) {
          setItems(res?.data?.roadmap);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getRoadmap();
  }, []);
  const DoneItems = () => {
    const temp: RoadmapItem[] = [];
    items?.map((item) => {
      if (item?.stage == "Done" || item?.progress == 100) {
        temp.push(item);
      }
    });
    return temp;
  };
  const TODOItems = () => {
    const temp: RoadmapItem[] = [];
    items?.map((item) => {
      if (item?.stage != "Done" || item?.progress != 100) {
        temp.push(item);
      }
    });
    return temp;
  };
  return (
    <>
      <div className="mx-auto pt-36 px-3 max-w-6xl">
        <section
          aria-labelledby="pricing-title "
          className="animate-slide-up-fade flex justify-center items-center flex-col"
          style={{
            animationDuration: "600ms",
            animationFillMode: "backwards",
          }}
        >
          {/* <Badge>Pricing</Badge> */}
          <h1 className="mt-2 inline-block bg-gradient-to-br from-gray-900 to-gray-800 bg-clip-text py-1 text-4xl font-bold tracking-tighter text-transparent sm:text-6xl md:text-6xl dark:from-gray-50 dark:to-gray-300">
            Roadmap
          </h1>
          <p className=" max-w-lg text-lg text-center text-gray-700 dark:text-gray-400">
            What’s coming next
          </p>
        </section>
        <Grid numItems={12} className="mt-12 gap-4 ">
          <Col
            numColSpan={12}
            numColSpanSm={6}
            className="sm:order-1 order-1 sm:mr-10 "
          >
            <span className="text-xl font-semibold text-black dark:text-slate-200 ">
              Done
            </span>
          </Col>
          <Col
            numColSpan={12}
            numColSpanSm={6}
            className="sm:order-2 order-3 sm:ml-10"
          >
            <span className="text-xl text-black font-semibold dark:text-slate-200">
              Coming soon
            </span>
          </Col>
          <div className="w-full h-[1px]  mb-8  bg-gray-200 col-span-12 order-3 sm:block hidden"></div>
          <Col
            numColSpan={12}
            numColSpanSm={6}
            className="sm:order-4 order-2 sm:mr-10 sm:mt-0 mt-4  "
          >
            <div className="flex flex-col justify-start items-start ">
              {DoneItems()?.map((item, index) => {
                return (
                  <>
                    <div className="flex flex-row gap-4">
                      <div className="flex flex-col h-full">
                        <div className=" border border-slate-500 rounded-full h-min p-1">
                          <RiCheckLine size={20} color={color} />
                        </div>
                        {index !== DoneItems().length - 1 && (
                          <div className="h-full min-h-14 ml-[14px] w-[1px] bg-slate-400"></div>
                        )}
                      </div>

                      <div className="flex flex-col justify-start items-start gap-2">
                        <span className="text-black dark:text-slate-100 text-xl">
                          {item.title}
                        </span>
                        <span className="text-gray-500 dark:text-slate-400">
                          {item.description}
                        </span>
                      </div>
                    </div>
                    {index !== DoneItems().length - 1 && (
                      <>
                        <div>
                          <div className="h-8 ml-[14px] w-[1px] bg-slate-400"></div>
                        </div>
                      </>
                    )}
                  </>
                );
              })}
            </div>
          </Col>
          <Col
            numColSpan={12}
            numColSpanSm={6}
            className="sm:order-5 order-4 sm:ml-10 sm:mt-0 mt-4  "
          >
            <div className="flex flex-col justify-start items-start ">
              {TODOItems()?.map((item, index) => {
                return (
                  <>
                    <div className="flex flex-row gap-4">
                      <div className="flex flex-col h-full">
                        <div className=" border border-slate-500 dark:border-gray-700 rounded-full h-min p-1 min-h-[30px] min-w-[30px]  ">
                          {(item?.stage != "Planned" ||
                            item?.progress != 0) && (
                            <>
                              <RiCodeSSlashLine size={20} color={color} />
                            </>
                          )}
                        </div>
                        {index !== TODOItems().length - 1 && (
                          <div className="h-full min-h-14 ml-[14px] w-[1px] bg-slate-400"></div>
                        )}
                      </div>

                      <div className="flex flex-col justify-start items-start gap-2">
                        <div className="flex flex-row gap-4">
                          <span className="text-black dark:text-slate-100 text-lg">
                            {item.title}
                          </span>
                          {(item?.stage != "Planned" ||
                            item?.progress != 0) && (
                            <>
                              <Badge className="sm:block hidden">
                                In Progress
                              </Badge>
                            </>
                          )}
                        </div>
                        <span className="text-gray-500 dark:text-slate-400">
                          {item.description}
                        </span>
                      </div>
                    </div>
                    {index !== TODOItems().length - 1 && (
                      <>
                        <div>
                          <div className="h-8 ml-[14px] w-[1px] bg-slate-400"></div>
                        </div>
                      </>
                    )}
                  </>
                );
              })}
            </div>
          </Col>
        </Grid>
      </div>
    </>
  );
}
