
import { RiPlayCircleLine, RiPlayFill } from '@remixicon/react'
import {
    ComponentType,
    FunctionComponent,
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { ThemeContext } from '../Theme'

interface CardProps {
    title: string
    logos?: string[]
    tag?: string
    onClick?: () => void
    option?: string
    description?: string
}

const UseCaseCard: FunctionComponent<CardProps> = ({
    title,

    tag,
    onClick,
    option,
    description,
    logos,
}) => {
     const [src, setSrc] = useState("black");
  const { theme, changeTheme } = useContext(ThemeContext);

     useEffect(() => {
       switch (theme) {
         case "light":
           setSrc("black");
           break;
         case "dark":
           setSrc("white");
           break;
         case "system":
           if (window.matchMedia) {
             // Check if the dark-mode Media-Query matches
             if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
               setSrc("white");
             } else {
               setSrc("black");
             }
           } else {
             setSrc("white");
           }
           break;

         default:
           setSrc("dark");
           break;
       }
     }, [theme]);

    return (
      <>
        <div
          onClick={() => {
            onClick?.();
          }}
          className=" cursor-pointer  h-fit sm:pb-8 pb-4 rounded-lg  shadow-2xl dark:border-none bg-slate-100 dark:bg-gray-800  flex flex-col justify-start  py-4 w-full gap-2 "
        >
          <div className="flex flex-row justify-between rounded-xl  items-center px-4 ">
            <div className="flex flex-row gap-2">
              {logos?.map((logo) => {
                return (
                  <div className=" bg-slate-300 dark:bg-slate-400 rounded p-2 flex items-center justify-center">
                    <img
                      src={logo}
                      className=" h-5 w-5"
                      onError={(e) => {
                        e.currentTarget.onerror = null;
                        e.currentTarget.src =
                          "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/default.svg";
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <div className="flex flex-row justify-center    rounded-xl p-2 items-center">
              <div className="flex w-full text-white flex-row justify-center items-center ">
                {/* <span>Run it</span> */}
                <RiPlayCircleLine className="w-8" color={src} />
              </div>
            </div>
          </div>
          {/* <br /> */}

          <div className=" text-start flex flex-col gap-1 text-black text-wrap px-4 h-full  ">
            <span className=" text-base dark:text-slate-300  text-ellipsis overflow-hidden w-full  text-nowrap">
              {title}
            </span>
            <span className="text-sm  text-slate-800 dark:text-slate-300 text-ellipsis overflow-hidden w-full  text-nowrap">
              {description}
            </span>
          </div>
        </div>
      </>
    );
}

export default UseCaseCard
