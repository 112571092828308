import { useState } from "react"
import Advantage from "../../components/ui/Advantage"
import CodeExample from "../../components/ui/CodeExample"
import Cta from "../../components/ui/Cta"
import { Faqs } from "../../components/ui/Faqs"
import Features2 from "../../components/ui/Features2"
import Hero from "../../components/ui/Hero"
import LogoCloud2 from "../../components/ui/LogoCloud2"
import Steps from "../../components/ui/Steps"
import UseCaseNew2 from "../../components/ui/UseCaseNew2"
import { Modal } from "@cloudscape-design/components"
import Cal from "@calcom/embed-react"

export default function Home() {
  const [open,setOpen] = useState(false);
  return (
    <main className="flex flex-col ">
      <Hero setOpen={setOpen} />
      <Features2 />
      <UseCaseNew2 />
      <CodeExample />

      <LogoCloud2 />

      <Steps setOpen={setOpen} />

      <Faqs />
      <Modal
        header="Try enterprise Edition"
        size="large"
        visible={open}
        onDismiss={() => setOpen(false)}
      >
        <Cal
          namespace="try"
          calLink="team/opencomply/try"
          style={{ width: "100%", height: "100%", overflow: "scroll" }}
          config={{ layout: "month_view" }}
        />
      </Modal>
    </main>
  );
}
