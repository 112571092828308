"use client";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../Accordion";
import { FAQ } from "../../pages/landing/faq";
import Balancer from "react-wrap-balancer";

import rehypeRaw from "rehype-raw";
import { Flex } from "@tremor/react";
import Badge from "../Badge";
import { RiExternalLinkLine } from "@remixicon/react";
import { useState } from "react";
import UseCaseCard from "../UseCaseCard";
import {
  KeyValuePairs,
  Modal,
  Pagination,
  Tabs,
} from "@cloudscape-design/components";
import Code from "../Code";
import { Results } from "../../pages/landing/query_result";

export function TabContent() {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState();

  const GetTab = () => {
    const temp = [
      {
        id: "0",
        label: "Results",
        content: (
          <>
            <div className="bg-[#282A36] rounded-xl p-4 w-full">
              <div className="flex flex-row flex-wrap w-full justify-between items-center pb-2">
                <span className="text-white text-xl w-fit ">
                  {/* @ts-ignore */}
                  Results ({selected?.results?.rows.length})
                </span>
                <div className=" w-fit custom-dark-pagination">
                  <Pagination
                    currentPageIndex={page + 1}
                    // @ts-ignore
                    pagesCount={Math.ceil(selected?.results?.rows.length / 10)}
                    onChange={({ detail }: any) => {
                      setPage(detail.currentPageIndex - 1);
                    }}
                  />
                </div>
              </div>
              <table className="table-auto w-full border-slate-500 p-4  rounded-lg   border-collapse   ">
                <thead className="mb-2 rounded-xl w-full">
                  <tr className="  rounded-xl  bg-gray-900">
                    {/* @ts-ignore */}

                    {selected?.results?.columns?.map((col: any) => {
                      return (
                        <>
                          <th className="text-white text-left       p-2 sm:p-4">
                            {col}
                          </th>
                        </>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="w-full">
                  {/* @ts-ignore */}
                  {selected?.results?.rows
                    .slice(page * 10, (page + 1) * 10)
                    ?.map((item :any, index :any) => {
                      return (
                        <tr
                          className={`  ${
                            index <
                            // @ts-ignore
                            selected?.results?.rows.slice(
                              page * 10,
                              (page + 1) * 10
                            ).length -
                              1
                              ? " border-b border-slate-400 "
                              : ""
                          }  bg-gray-700`}
                        >
                          {Object.keys(item).map((key) => {
                            return (
                              <td className="text-white    p-2 sm:p-4">
                                {item[key]}
                              </td>
                            );
                          })}
                         
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </>
        ),
      },
    ];
    if (window.innerWidth > 768) {
      temp.push({
        id: "1",
        label: "Query",
        content: (
          <Code
          // @ts-ignore
            code={selected?.query}
            lang="sql"
            copy={true}
            className=""
          />
        ),
      });
    }
    return temp;
  };
  const cards = window.innerWidth >640 ? Results : Results.slice(0,3)
  return (
    <>
      <div className=" h-full w-full flex justify-center        sm:p-7 p-2 ">
        <div className="sm:grid grid-cols-12 w-full flex flex-col  gap-8  ">
          {cards?.map((card: any) => {
            return (
              <div className=" col-span-full  md:col-span-6 ">
                <UseCaseCard
                  // @ts-ignore
                  title={card?.title}
                  description={card?.description}
                  logos={card?.logos}
                  onClick={() => {
                    // @ts-ignore
                    setOpen(true);
                    setSelected(card);
                  }}
                  tag="tag1"
                />
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        visible={open}
        size="large"
        onDismiss={() => {
          setOpen(false);
          // setSelected(undefined);
        }}
        // @ts-ignore
        header={selected?.title}
      >
        <KeyValuePairs
          items={[
            {
              label: "Description",
              // @ts-ignore
              value: selected?.description,
            },
            {
              label: "Integration Plugins",
              // @ts-ignore
              value: (
                <>
                  <div className="flex flex-wrap flex-row gap-2 mt-2">
                    {/* @ts-ignore */}
                    {selected?.plugins?.map((plugin) => {
                      return (
                        <>
                          <a
                            target="__blank"
                            href={plugin.url}
                            className={`p-2   px-4 border flex flex-row gap-2 items-center justify-center dark:border-white text-black dark:text-white  rounded-3xl cursor-pointer sm:hover:dark:bg-white sm:hover:bg-blue-950 sm:hover:text-white sm:hover:dark:text-black    `}
                          >
                            <span>{plugin.name}</span>
                            <RiExternalLinkLine />
                          </a>
                        </>
                      );
                    })}
                  </div>
                </>
              ),
            },
          ]}
        />
        <Tabs tabs={GetTab()} />
      </Modal>
    </>
  );
}
